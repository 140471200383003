import { toast } from "react-toastify";
import Web3 from "web3";
import { NetworkHelper } from "../utils/networkHelper";
import walletconnectService from "./walletconnect.service";

class Web3Service {
  web3;

  initialize = async (wallet, chainId, redux) => {
    try {
      if (wallet === "METAMASK" || wallet == undefined || wallet == "") {
        const { ethereum } = window;
        if (ethereum && ethereum.isMetaMask) this.web3 = new Web3(ethereum);
      } else if (wallet === "WALLETCONNECT") {
        const { provider } = await walletconnectService.enableWallet(
          redux,
          chainId
        );
        this.web3 = new Web3(provider);
      }

      return this.web3;
    } catch (error) {
      toast.error(error.message);
      // console.log("Web3 Error:", error);
      throw error;
    }
  };

  initContract = async (abi, address, wallet) => {
    const web3 = await this.initialize(wallet);
    return new web3.eth.Contract(abi, address);
  };

  getNetworkId = async () => {
    return new this.web3.eth.net.getId();
  };

  getAccounts = async (wallet, chainId, redux) => {
    this.web3 = await this.initialize(wallet, chainId, { redux });
    return await this.web3.eth.getAccounts();
  };

  getBalance = async (account) => {
    return new this.web3.eth.getBalance(account);
  };

  changeNetwork = async ({ name, chainId, walletType, dispatch }) => {
    try {
      if (!walletType || walletType === "METAMASK") {
        this.handleChangeNetworkMetamask();
      } else if (walletType === "WALLETCONNECT") {
        this.handleChangeNetworkWalletConnect(walletType, chainId, dispatch);
      }
    } catch (err) {
      // This error code indicates that the chain has not been added to MetaMask.
      if (!walletType || walletType === "METAMASK") {
        if (err.code === 4902) {
          this.handleAddNetworkMetamask(name, chainId);
        }
      }
      if (walletType === "WALLETCONNECT") {
        if (!err.code) {
          this.handleAddNetworkWalletConnect(
            name,
            walletType,
            chainId,
            dispatch
          );
        }
      }
    }
  };

  handleChangeNetworkMetamask = async (chainId) => {
    if (window.ethereum.networkVersion != chainId) {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: this.web3.utils.toHex(chainId) }],
      });
    }
  };

  handleChangeNetworkWalletConnect = async (walletType, chainId, dispatch) => {
    this.web3 = await this.initialize(walletType, chainId, { dispatch });
    console.log({ web3: this.web3 });
    await this.web3.currentProvider.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: this.web3.utils.toHex(chainId) }],
    });
  };

  handleAddNetworkMetamask = async (name, chainId) => {
    await window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: [
        {
          ...NetworkHelper.getNetworkData(name),
          chainId: this.web3.utils.toHex(chainId),
        },
      ],
    });
  };

  handleAddNetworkWalletConnect = async (
    name,
    walletType,
    chainId,
    dispatch
  ) => {
    this.web3 = await this.initialize(walletType, chainId, { dispatch });
    console.log({ web3: this.web3 });
    await this.web3.currentProvider.request({
      method: "wallet_addEthereumChain",
      params: [
        {
          ...NetworkHelper.getNetworkData(name),
          chainId: this.web3.utils.toHex(chainId),
        },
      ],
    });
  };

  enableWallet = () => {
    window.ethereum.enable();
  };

  connectMetamask = async () => {
    try {
      const { ethereum } = window;
      if (!ethereum) throw new Error("Please install metamask first");
      let address = await ethereum.request({
        method: "eth_requestAccounts",
      });
      return address;
    } catch (error) {
      // console.log(error);
      toast.error(error.message);
      // throw error;
    }
  };
}

export default new Web3Service();
