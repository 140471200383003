import WalletConnect from "./walletconnect.service";
import web3Service from "./web3.service";
import { Enviroments } from '../constants/constants'
import { toast } from "react-toastify";

class Wallet {

  static async connectWallet(wallet, redux) {
    let accounts = null;
    
    if (wallet === Enviroments.WALLET_TYPES.walletConnect) {
      accounts = await WalletConnect.enableWallet(redux);
      accounts = accounts.address;
    } else if (wallet === Enviroments.WALLET_TYPES.metamask) {
      accounts = await web3Service.connectMetamask()
    }

    if ((accounts) && (accounts.length === 0)) return toast.error("Failed to connect wallet.");
    accounts = accounts[0];
    return accounts
  }
}

export default Wallet;
