import { toasts as toast } from "../../components/common/Toast/Toast";
import { ApiService } from "../../services/api.service";
import contractService from "../../services/contract.service";
import web3Service from "../../services/web3.service";
import types from "../types";
import { LoadingActions } from "./loading.action";
import MySwal from "../../services/swal.service";
import BigNumber from "bignumber.js";
import swalService from "../../services/swal.service";

const saveCollection = (data) => ({
  type: types.collection.SAVE_COLLECTION,
  payload: data,
});

const allowNFTCreation = (data) => ({
  type: types.collection.ALLOW_NFT_CREATION,
  payload: data,
});

const saveCollectionWalletAddress = (data) => {
  return {
    type: types.collection.SAVE_COLLECTION_WALLET_ADDRESS,
    payload: data,
  };
};

const saveNftPage = (data) => {
  
  return {
    type: types.collection.SAVE_NFT_PAGE,
    payload: data,
  };
};


const saveWalletType = (data) => {
  return {
    type: types.collection.SAVE_WALLET_TYPE,
    payload: data,
  };
};

const createCollection = (data) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        const token = getState().persist.jwtToken;
        const headers = {
          "content-type": "application/json",
          jwt: token,
        };
        let res = await ApiService.createCollection(data, headers);
        resolve(res);
      } catch (error) {
        // console.log(error);
        reject(error);
      }
    });
  };
};

const getCollectionDetailsByExternalLink = (data) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        const token = getState().persist.jwtToken;
        const headers = {
          "content-type": "application/json",
          jwt: token,
        };
        let res = await ApiService.getCollectionDetailsByExternalLink(
          data,
          headers
        );
        resolve(res);
      } catch (error) {
        // console.log(error);
        reject(error);
      }
    });
  };
};

const createCollectionNfts = (data) => {
  return async (dispatch, getState) => {
    const { startLoader, stopLoader } = LoadingActions;
    return new Promise(async (resolve, reject) => {
      try {
        const token = getState().persist.jwtToken;
        const headers = {
          "content-type": "application/json",
          jwt: token,
        };
        let res = await ApiService.createCollectionNfts(data, headers);
        resolve(res);
      } catch (error) {
        // console.log(error);
        reject(error);
      }
    });
  };
};

const whitelistUser = (data) => {
  return async (dispatch, getState) => {
    const { startLoader, stopLoader } = LoadingActions;
    return new Promise(async (resolve, reject) => {
      try {
        await dispatch(startLoader());
        const token = getState().persist.jwtToken;
        const headers = {
          "content-type": "application/json",
          jwt: token,
        };

        let res = await ApiService.whitelistUser(data, headers);
        await dispatch(stopLoader());
        setTimeout(() => {
          MySwal.toaster(
            "You have been added to whitelist",
            "success",
            "top-end"
          );
        }, 4000);
        toast.success(
          `Wallet ${data.walletAddress} has been added to the whitelist`,
          2000
        );
        resolve(res);
      } catch (error) {
        await dispatch(stopLoader());
        reject(error);
      }
    });
  };
};
/**
 * @function getCollections
 * @param {{ page, limit }} data
 * @returns /nfts/collection/getCollections
 */
export const getCollections = (data) => async (dispatch, getState) => {
  const { startLoader, stopLoader } = LoadingActions;
  try {
    dispatch(startLoader());
    const token = getState().persist.jwtToken;
    const headers = {
      "content-type": "application/json",
      jwt: token,
    };
    // const { setHeaders } = PersistActions;
    // const headers = await dispatch(setHeaders({ isFormdata: false }));
    const res = await ApiService.getCollections(data, headers);
    dispatch(stopLoader());
    return res;
  } catch (error) {
    dispatch(stopLoader());
    throw error;
  }
};

export const getCollectionNfts = (data) => async (dispatch, getState) => {
  const { startLoader, stopLoader } = LoadingActions;
  try {
    dispatch(startLoader());
    const token = getState().persist.jwtToken;
    const headers = {
      "content-type": "application/json",
      jwt: token,
    };
    const res = await ApiService.getCollectionNfts(data, headers);
    dispatch(stopLoader());
    return res;
  } catch (error) {
    dispatch(stopLoader());
    throw error;
  }
};

export const getNftsForMint =
  (data, collectionDetails, contract, isPrivate) =>
  async (dispatch, getState) => {
    const { startLoader, stopLoader } = LoadingActions;
    try {
      dispatch(startLoader());
      const token = getState().persist.jwtToken;
      const headers = {
        "content-type": "application/json",
        jwt: token,
      };
      let res = null;
      if (isPrivate) {
        res = await ApiService.getPrivateNftsForMint(data, headers);
      } else {
        res = await ApiService.getPublicNftsForMint(data, headers);
      }

      let finalRes = await dispatch(
        checkNftBeforeMint(
          data.limit,
          res.data.data.list,
          collectionDetails,
          contract,
          isPrivate
        )
      );
      dispatch(stopLoader());
      return finalRes;
    } catch (error) {
      dispatch(stopLoader());
      throw error;
    }
  };

const checkNftBeforeMint =
  (limit, list, collectionDetails, contract, isPrivate) =>
  async (dispatch, getState) => {
    try {
      if (list && list.length === 0) {
        throw new Error("There are no NFTs available for minting");
      } else {
        if (limit > list.length) {
          let actionResponse = await swalService.confirmAction(`There are only ${list.length} NFT's available to mint. Do you want to mint them ?`);
          if(!actionResponse.isConfirmed){
            return false

          }
        }
        return await dispatch(
          mintAndTransfer(list, collectionDetails, contract, isPrivate)
        );
      }
    } catch (error) {
      throw error;
      // toast.error(error);
    }
  };

const mintAndTransfer =
  (list, collectionDetails, contract, isPrivate) =>
  async (dispatch, getState) => {
    try {
      const {blockChain:{chainId}} = collectionDetails
      const { collection: { collections: { walletType } } } = getState();
      const web3 = await web3Service.initialize(walletType,chainId,{dispatch});

      let {
        name,
        description,
        seller,
        royality,
        commission,
        price,
        buyer,
        _id: collectionId,
      } = collectionDetails;

      let { contractAddress: proxyAddress } = contract;
      let tokenIds = [],
        nftIds = [],
        ipfsArray = [],
        tokenUris = [];
      console.log("length",list.length)
      const { updateNftStatusToMinted } = ApiService;
      let isMetadataReady = true;
      for (const obj of list) {
        if (!obj.metadata) {
          isMetadataReady = false;
        }
      }
      if (!isMetadataReady) {
        throw new Error("Metadata not ready, please try again later");
      }
      for (const obj of list) {
        nftIds.push(obj["_id"]);
        // if (!obj.metadata) {
        //   throw new Error("Metadata doesn't exist");
        // }
        tokenIds.push(String(obj.metadata.tokenId));
        tokenUris.push(String(obj.metadata.uri));
      }

      let txAmount = BigNumber(price).multipliedBy(list.length);
      let bn = BigNumber(txAmount).toFixed();
      txAmount = await web3.utils.toWei(bn, "ether");
      price = await web3.utils.toWei(price, "ether");
      // let balance = await web3Service.getBalance(buyer);
      let balance = await new web3.eth.getBalance(buyer);
      txAmount = Number(txAmount).toFixed()
      // if (balance < txAmount){
      //   throw "Insufficient funds"
      // }
      let { commission: adminCommission, adminAddress } = commission;
      adminCommission = adminCommission * 10;
      
      if (isPrivate) {
        return await contractService.mintTokenByCreator(
          { tokenIds, tokenUris, proxyAddress, buyer },
          nftIds,
          collectionId,
          updateNftStatusToMinted
        );
      }

      return await contractService.mintTokenAndTransfer(
        {
          tokenIds,
          tokenUris,
          seller,
          buyer,
          price,
          adminCommission,
          royality: royality * 10,
          proxyAddress,
        },
        txAmount,
        nftIds,
        balance,
        updateNftStatusToMinted,
        adminAddress,
        collectionId,
        {
          walletType,
          chainId,
          dispatch
        }
      );
    } catch (error) {
      // console.log(error);
      // toast.error("failed!");
      throw error;
    }
  };

export const checkIsWhiteListed = (data) => async (dispatch, getState) => {
  try {
    const token = getState().persist.jwtToken;
    const headers = {
      "content-type": "application/json",
      jwt: token,
    };
    return await ApiService.isWhiteListedUser(data, headers);
  } catch (error) {
    throw error;
  }
};

export const getMintedNftsCount =
  (collectionId, role) => async (dispatch, getState) => {
    try {
      const token = getState().persist.jwtToken;
      const headers = {
        "content-type": "application/json",
        jwt: token,
      };
      return await ApiService.getMintedNftsCount(collectionId, role, headers);
    } catch (error) {
      throw error;
    }
  };

export const getContracts = () => async (dispatch, getState) => {
  try {
    const token = getState().persist.jwtToken;
    const headers = {
      "content-type": "application/json",
      jwt: token,
    };
    return await ApiService.getContracts(headers);
  } catch (error) {
    throw error;
  }
};

export const collectionGoLive = (data) => async (dispatch, getState) => {
  try {
    const token = getState().persist.jwtToken;
    const headers = {
      "content-type": "application/json",
      jwt: token,
    };
    return await ApiService.collectionGoLive(data, headers);
  } catch (error) {
    throw error;
  }
};

export const updateCustomization = (id, data) => async (dispatch, getState) => {
  const { startLoader, stopLoader } = LoadingActions;
  try {
    dispatch(startLoader());
    const token = getState().persist.jwtToken;
    const headers = {
      "content-type": "application/json",
      jwt: token,
    };
    const res = await ApiService.updateCustomization(id, data, headers);
    dispatch(stopLoader());
    return res;
  } catch (error) {
    dispatch(stopLoader());
    throw error;
  }
};

export const getCustomization = (data) => async (dispatch, getState) => {
  const { startLoader, stopLoader } = LoadingActions;
  try {
    dispatch(startLoader());
    const token = getState().persist.jwtToken;
    const headers = {
      "content-type": "application/json",
      jwt: token,
    };
    const res = await ApiService.getCustomization(data, headers);
    dispatch(stopLoader());
    return res;
  } catch (error) {
    dispatch(stopLoader());
    throw error;
  }
};

export const addAttribute = (data) => async (dispatch, getState) => {
  try {
    const token = getState().persist.jwtToken;
    const headers = {
      "content-type": "multipart/form-data",
      jwt: token,
    };
    const res = await ApiService.addAttribute(data, headers);
    return res;
  } catch (error) {
    throw error;
  }
};

export const getContractByBlockChain = (data, apiData) => async (dispatch, getState) => {
  const { startLoader, stopLoader } = LoadingActions;
  try {
    dispatch(startLoader());
    let {
      name: collectionName,
      symbol,
      serialId,
      blockChain,
      walletAddress
    } = data;
    const token = getState().persist.jwtToken;
    const headers = {
      "content-type": "application/json",
      jwt: token,
    };
    if (blockChain._id) {
      blockChain = blockChain._id;
    }
    const res = await ApiService.getContractByBlockChain(blockChain, headers);
    if (!!res.data.data) {
      console.log("contractdata",res.data.data)
      const { proxyAddress, chainId, blockChain: name } = res.data.data;
      let newData = {
        chainId,
        name,
      };
      await web3Service.changeNetwork(newData);
      
      let response = await contractService.createNewERC21(
        collectionName,
        serialId,
        proxyAddress,
        (symbol || "symbol").toUpperCase(),
        walletAddress,
        ApiService.collectionGoLive,
        apiData,
        headers
      );

      dispatch(stopLoader());
      return response;
    } else {
      toast.error("No data available");
      dispatch(stopLoader());
    }
  } catch (error) {
    dispatch(stopLoader());
    throw error;
  }
};

export const getNftDetails = (nftId) => async (dispatch, getState) => {
  const { startLoader, stopLoader } = LoadingActions;
  try {
    dispatch(startLoader());
    const token = getState().persist.jwtToken;
    const headers = {
      "content-type": "application/json",
      jwt: token,
    };

    const res = await ApiService.getNftDetails(nftId, headers);

    dispatch(stopLoader());
    return res;
  } catch (error) {
    dispatch(stopLoader());
    throw error;
  }
};

export const deleteDraft = (id) => async (dispatch, getState) => {
  const { startLoader, stopLoader } = LoadingActions;
  try {
    dispatch(startLoader());
    const token = getState().persist.jwtToken;
    const headers = {
      "content-type": "application/json",
      jwt: token,
    };

    const res = await ApiService.deleteDraft(id, headers);

    dispatch(stopLoader());
    toast.success(res.data.message);
    return res;
  } catch (error) {
    dispatch(stopLoader());
    throw error;
  }
};

const saveNftUpload = (data) => ({
  type: types.collection.SAVE_NFT_UPLOAD,
  payload: data,
});

const getMintedCountPerWallet = (data) => async (dispatch, getState) => {
  try {
    const token = getState().persist.jwtToken;
    const headers = {
      "content-type": "application/json",
      jwt: token,
    };

    const res = await ApiService.getMintedCountPerWallet(data, headers);
    return res;
  } catch (error) {
    throw error;
  }
};

const getInvitationCode = (data) => async (dispatch, getState) => {
  const { startLoader, stopLoader } = LoadingActions;
  try {
    dispatch(startLoader());
    const token = getState().persist.jwtToken;
    const headers = {
      "content-type": "application/json",
      jwt: token,
    };

    const res = await ApiService.getInvitationCode(data, headers);
    dispatch(stopLoader());
    toast.success(`Invitation code generated successfully.`, 2000);
    return res;
  } catch (error) {
    dispatch(stopLoader());
    toast.error(error);
    throw error;
  }
};

const getInvitations = (data) => async (dispatch, getState) => {
  const { startLoader, stopLoader } = LoadingActions;
  try {
    dispatch(startLoader());
    const token = getState().persist.jwtToken;
    const headers = {
      "content-type": "application/json",
      jwt: token,
    };
    const res = await ApiService.getInvitations(data, headers);
    dispatch(stopLoader());

    return res;
  } catch (error) {
    dispatch(stopLoader());
    toast.error(error);
    throw error;
  }
};

const getInvitationDetailByInviteCode =
  (data) => async (dispatch, getState) => {
    const { startLoader, stopLoader } = LoadingActions;
    try {
      dispatch(startLoader());
      const token = getState().persist.jwtToken;
      const headers = {
        "content-type": "application/json",
        jwt: token,
      };

      const res = await ApiService.getInvitationDetailByInviteCode(
        data,
        headers
      );
      dispatch(stopLoader());
      return res;
    } catch (error) {
      dispatch(stopLoader());
      toast.error(error);
      throw error;
    }
  };

const deleteInvitation = (id) => async (dispatch, getState) => {
  const { startLoader, stopLoader } = LoadingActions;
  try {
    dispatch(startLoader());
    const token = getState().persist.jwtToken;
    const headers = {
      "content-type": "application/json",
      jwt: token,
    };

    const res = await ApiService.deleteInvitation(id, headers);

    dispatch(stopLoader());
    toast.success(res.data.message);
    return res;
  } catch (error) {
    dispatch(stopLoader());
    throw error;
  }
};

const updateMaxPerWallet = (data) => async (dispatch, getState) => {
  const { startLoader, stopLoader } = LoadingActions;
  try {
    dispatch(startLoader());
    const token = getState().persist.jwtToken;
    const headers = {
      "content-type": "application/json",
      jwt: token,
    };
    const res = await ApiService.updateMaxPerWallet(data, headers);
    dispatch(stopLoader());
    toast.success(res.data.message);
    return res;
  } catch (error) {
    dispatch(stopLoader());
    throw error;
  }
};

const uploadWhitelistUsersCsv = (data) => async (dispatch, getState) => {
  const { startLoader, stopLoader } = LoadingActions;
  try {
    const token = getState().persist.jwtToken;
    const headers = {
      "content-type": "application/json",
      jwt: token,
    };

    dispatch(startLoader());
    const res = await ApiService.uploadWhitelistUsersCsv(data, headers);
    toast.success(res.data.message);
    return res;
  } catch (error) {
    throw error;
  } finally {
    dispatch(stopLoader());
  }
};

const updateCollection = (id, data) => async (dispatch, getState) => {
  const { startLoader, stopLoader } = LoadingActions;
  try {
    const token = getState().persist.jwtToken;
    const headers = {
      "content-type": "application/json",
      jwt: token,
    };

    dispatch(startLoader());
    const res = await ApiService.updateCollection(id, data, headers);
    toast.success(res.data.message);
    return res;
  } catch (error) {
    throw error;
  } finally {
    dispatch(stopLoader());
  }
};

const getPrivateMintedNfts = (data) => async (dispatch, getState) => {
  const { startLoader, stopLoader } = LoadingActions;
  try {
    const token = getState().persist.jwtToken;
    const headers = {
      "content-type": "application/json",
      jwt: token,
    };

    dispatch(startLoader());
    const res = await ApiService.getPrivateMintedNfts(data, headers);
    return res;
  } catch (error) {
    throw error;
  } finally {
    dispatch(stopLoader());
  }
};

const transferNft = (data, hideModal) => async (dispatch, getState) => {
  const { startLoader, stopLoader } = LoadingActions;
  try {
    const token = getState().persist.jwtToken;
    const headers = {
      "content-type": "application/json",
      jwt: token,
    };

    dispatch(startLoader());
    const { updateNftOwner } = ApiService;
    let res = await contractService.transferTokenByCreator(
      data,
      updateNftOwner,
      headers
    );
    toast.success("Owner has been updated");
    return res;
  } catch (error) {
    throw error;
  } finally {
    dispatch(stopLoader());
  }
};

const getPrivateNftsSupplyCount = (data) => async (dispatch, getState) => {
  const { startLoader, stopLoader } = LoadingActions;
  try {
    dispatch(startLoader());
    const { getPrivateNftsSupplyCount } = ApiService;
    let res = await getPrivateNftsSupplyCount(data);

    return res;
  } catch (error) {
    throw error;
  } finally {
    dispatch(stopLoader());
  }
};

const getAllCollectionsHomePage = (data) => async (dispatch, getState) => {
  const { startLoader, stopLoader } = LoadingActions;
  try {
    const token = getState().persist.jwtToken;
    const headers = {
      "content-type": "application/json"
    };

    const { getAllCollectionsHomePage } = ApiService;
    let res = await getAllCollectionsHomePage(data, headers);
    return res;
  } catch (error) {
    throw error;
  } finally {
    dispatch(stopLoader());
  }
};

export const CollectionActions = {
  saveCollection,
  createCollection,
  getCollectionDetailsByExternalLink,
  createCollectionNfts,
  allowNFTCreation,
  whitelistUser,
  saveCollectionWalletAddress,
  getCollectionNfts,
  getCollections,
  getNftsForMint,
  checkIsWhiteListed,
  getMintedNftsCount,
  getContracts,
  collectionGoLive,
  updateCustomization,
  getCustomization,
  addAttribute,
  getNftDetails,
  deleteDraft,
  saveNftUpload,
  getMintedCountPerWallet,
  getInvitationCode,
  getInvitations,
  getInvitationDetailByInviteCode,
  deleteInvitation,
  updateMaxPerWallet,
  uploadWhitelistUsersCsv,
  getContractByBlockChain,
  saveNftPage,
  updateCollection,
  getPrivateMintedNfts,
  transferNft,
  getPrivateNftsSupplyCount,
  getAllCollectionsHomePage,
  saveWalletType
};
