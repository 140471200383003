import * as AWS from "aws-sdk";

class S3Service {
  s3 = null;
  isCorsSet = false;
  defaultBucketName = process.env.REACT_APP_S3_BUCKET;
  constructor() {
    this.initS3();
    // console.log(AWS.config);
    this.s3 = new AWS.S3({ apiVersion: "2006-03-01" });
  }
  /**
   * @function initS3
   * @returns S3
   */

  async initS3() {
    AWS.config.update({
      accessKeyId: process.env.REACT_APP_S3_ACCESS_ID,
      secretAccessKey: process.env.REACT_APP_S3_SECRET_ID,
      region: process.env.REACT_APP_S3_REGION,
    });
  }

  /**
   * @function uploadFileToS3Bucket
   * @param file
   * @returns error | data
   */
  async uploadFileToS3Bucket(file, folder) {
    return new Promise(async (resolve, reject) => {
      let selectedFile = file;
      const bucketName = this.defaultBucketName;
      const ext = selectedFile.name.split(".")[1];
      let fileName = selectedFile.name.split(".")[0].split(" ").join("-");
      fileName = `${fileName}-${new Date().getTime()}.${ext}`;
      // const fileBuffer = await this.toBase64(selectedFile);
      // console.log(fileBuffer);

      const params = {
        Bucket: bucketName,
        Key: !!folder  ? folder+"/" + fileName : fileName,
        Body: selectedFile,
        ACL: "public-read",
        ContentType: file["mimetype"],
      };

      this.s3.upload(params, (error, data) => {
        if (error)
          reject(error); /** return error if any otherwise, return data */
        resolve(data);
      });
    });
  }
}

export default new S3Service();
