const getNetworkData = (network) => {
    // console.log("environment", process.env.NODE_ENV);
    let additionalProperties = {};
    if (
      process.env.REACT_APP_ENV === "development" ||
      process.env.REACT_APP_ENV === "staging"
    ) {
      if (network === "BSC") {
        additionalProperties = getNetworkProperties("BSC Testnet", "BNB", [
          "https://data-seed-prebsc-1-s1.binance.org:8545/",
        ]);
      } else if (network === "ETH") {
        additionalProperties = getNetworkProperties("Goerli test network", "ETH", [
          "https://goerli.infura.io/v3/",
        ]);
      } else if (network === "MATIC") {
        additionalProperties = getNetworkProperties("Polygon-Mumbai", "MATIC", [
          "https://rpc-mumbai.maticvigil.com/",
        ]);
      }
    } else {
      if (network === "BSC") {
        additionalProperties = getNetworkProperties("BSC Mainnet", "BNB", [
          "https://bsc-dataseed1.binance.org",
        ]);
      } else if (network === "ETH") {
        additionalProperties = getNetworkProperties("ETH Mainnet", "ETH", [
          "https://api.mycryptoapi.com/eth",
        ]);
      } else if (network === "MATIC") {
        additionalProperties = getNetworkProperties("Polygon Mainnet", "MATIC", [
          "https://polygon-rpc.com/",
        ]);
      }
    }
    return additionalProperties;
  };
  
  const getNetworkProperties = (chainName, symbol, rpcUrls) => {
    return {
      chainName,
      nativeCurrency: { decimals: 18, symbol: symbol },
      rpcUrls,
    };
  };

  export const NetworkHelper = {
    getNetworkData
  }