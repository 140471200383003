import { Container, Image } from "react-bootstrap";
import { Field, Form } from "redux-form";
import { FormField } from "../../../common/FormField";

import React, { useEffect, useState } from "react";
import Button from "@restart/ui/esm/Button";
import { ProgressBar } from "react-bootstrap";
import { LoadingActions } from "../../../../redux/actions/loading.action";
import { reduxFormHelper } from "../../../../HOC/redux.form.helper";
import S3Service from "../../../../services/s3.service";
import { toasts as toast } from "../../../common/Toast/Toast";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import { required } from "redux-form-validators";
import { Layout } from "../../../common";
import { CollectionActions } from "../../../../redux/actions/collection.action";
import styles from "./uploadNFT.module.scss";
import AttributesModal from "../../../common/AttributesModal/AttributesModal";
import { Enviroments } from "../../../../constants/constants";
import carbon from "../../../../assets/images/carbon.svg";
import web3Service from "../../../../services/web3.service";
import fileValidationService from "../../../../services/fileValidation.service";

function UploadNft(props) {
  const { _id: userId } = useSelector((state) => state.persist.user);
  const isNFTCreationAllowed = useSelector(
    (state) => state.collection.isNFTCreationAllowed
  );
  const { handleSubmit } = props;
  const [uploads, setUploads] = useState([]);
  const [currentChunk, setCurrentChunk] = useState(1);
  const [files, setFiles] = useState([]);
  const [uploading, setUploading] = useState("pending");
  const [percentageUploaded, setPercentageUploaded] = useState(0);

  const [masterUploads, setMasterUploads] = useState([]);
  const [showAttributeModal, setShowAttributeModal] = useState(false);
  const dispatch = useDispatch();
  let {
    match: {
      params: { collectionId },
    },
  } = props;

  let { history } = props;

  let timeout = null;

  const nftUploads = useSelector(
    (state) => state?.collection?.currentCollection?.nftUploads
  );

  const ownerWalletAddress = useSelector((state)=>state.persist.walletAddress);

  useEffect(()=>{
    console.log({ownerWalletAddress})
  },[ownerWalletAddress])

  useEffect(() => {
    if (isNFTCreationAllowed == false)
      history.push("/auth/collection/CreateNew");
  }, [isNFTCreationAllowed]);

  useEffect(() => {
    resetEverything();
  }, [props.items]);

  useEffect(() => {
    if (uploading == "uploaded") {
      setMasterUploads((prevUploads) => {
        return [...uploads, ...prevUploads];
      });
      setUploads([]);
    }
  }, [uploading]);

  useEffect(() => {
    if (files.length != 0) chunkUpload();
  }, [files]);

  useEffect(() => {
    if (uploads.length != 0) chunkUpload();
  }, [currentChunk, uploads]);

  const selectImage = (fileObj) => {
    if (Object.keys(fileObj).length > 0) {
      uploadItems(fileObj);
    } else {
      setFiles([]);
    }
  };

  const acceptedfileList = [
    "image/jpeg",
    "image/jpg",
    "image/png",
    "image/gif",
    "image/svg",

    // ".glb",
    // ".gltf"
  ];

  const handleClick = (id) => {
    resetEverything();
    if (uploading != "uploading") {
      document.getElementById(id).click();
    }
  };

  const uploadItems = async(fileObj) => {
    let items = Object.values(fileObj);
    let fileArray = [];

    if (!!items && items.length > 0) {
      let canWeUpload = await areFilesUploadable(items);
      if (canWeUpload) {
        fileArray = Object.keys(items).map((key, index) => ({
          file: items[key],
          serial: items[key]["name"].split(".")[0],
          user: userId,
          creator: ownerWalletAddress,
          owner: ownerWalletAddress, //owner walletaddress
        }));
        setFiles(fileArray);
      } else return;
    } else {
      toast.error("Please select files to continue");
    }
  };

  const emptyUploadsArray = () => {
    setUploads([]);
  };

  const areFilesUploadable = async (items) => {
    const {
      NFT_LIMITS: { FILE_SIZE_LIMIT, UPLOAD_LIMIT },
    } = Enviroments;

    let isFileLessThanFileSizeLimit = true;
    let isFileCorrectExtension = true
    if (items.length > 0) {
      for(var item of items){
        isFileCorrectExtension = fileValidationService.validate(item, acceptedfileList);
        if(!isFileCorrectExtension){
          break;
        }
      }
      
    }
    
    if(!isFileCorrectExtension){
      toast.error(`File must be image only`);
      return false;
    }

    if (
      nftUploads &&
      nftUploads != 0 &&
      (nftUploads > UPLOAD_LIMIT || nftUploads + items.length > UPLOAD_LIMIT)
    ) {
      toast.error(`You can't upload more than ${UPLOAD_LIMIT} files`);
      return false;
    }

    Object.values(items).forEach((file) => {
      if (file.size / 1024 / 1024 >= FILE_SIZE_LIMIT) {
        isFileLessThanFileSizeLimit = false;
      }
    });

    if (!isFileLessThanFileSizeLimit) {
      toast.error(`File size can't be greater than ${FILE_SIZE_LIMIT} mb`);
      return false;
    } else if (items.length > UPLOAD_LIMIT) {
      toast.error(`You can select only ${UPLOAD_LIMIT} files`);
      return false;
    }
    let isFileInSequence = true;
    Object.values(items).forEach((file, index) => {
      if (file.name.split(".")[0] != index + 1) {
        isFileInSequence = false;
      }
    });

    if (!isFileInSequence) {
      toast.error("Please upload files in sequence");
      return false;
    }

    let mintPerwallet = await getCollectionDetails(history.location.state?.externalLink)
    if(!mintPerwallet || items.length < mintPerwallet){
      toast.error("Supply can't be less than "+mintPerwallet);
      return false
    }

    return true;
  };

  const resetEverything = () => {
    setCurrentChunk(1);
    // setFiles([]);
    setPercentageUploaded(0);
    setUploading("pending");
  };

  const chunkUpload = async () => {
    try {
      await dispatch(LoadingActions.startLoader());
      let tempArray = [];
      let chunkSize = 100;
      let chunks = Math.ceil(files.length / chunkSize);
      if (currentChunk <= chunks) {
        let newArr = files.slice(
          (currentChunk - 1) * chunkSize,
          currentChunk * chunkSize
        );
        newArr.forEach((file, index) => {
          uploadingFile(file, index, newArr, tempArray);
        });
      } else {
        await dispatch(LoadingActions.stopLoader());
      }
    } catch (error) {
      toast.error("Failed uploading files");
    }
  };

  const uploadingFile = async (file, index, chunkArray, tempArray) => {
    try {
      let res = await S3Service.uploadFileToS3Bucket(
        file.file,
        history.location.state.externalLink
      );
      // await dispatch(LoadingActions.stopLoader());
      setUploading("uploading");
      let uploadObj = {
        url: res.Location,
        type: file.file.type,
        collectionId,
        serial: file.serial,
        fileName: file.file.name,
        user: userId,
        creator: ownerWalletAddress,
        owner: ownerWalletAddress, //owner walletaddress
      };
      tempArray.push(uploadObj);
      uploads.push(uploadObj);
      let percentage = calcPercentageUploaded();
      // console.log("percentage", percentage);
      setPercentageUploaded(percentage);
      // console.log(index + 1 + " uploaded out of " + chunkArray.length);
      if (tempArray.length == chunkArray.length) {
        // call api here
        setUploads(uploads.sort((a, b) => a.serial - b.serial));
        await createNftsInChunks(
          uploads.slice((currentChunk - 1) * 100, currentChunk * 100)
        );
      }
      if (uploads.length == files.length) {
        setUploading("uploaded");
      }
    } catch (error) {
      // console.log(error);
      throw error;
    }
  };

  const calcPercentageUploaded = () => {
    return Math.ceil((uploads.length / files.length) * 100);
  };

  const showFileUploadedSuccessMessage = () => {
    if (uploads.length != 0) {
      if (uploading == "uploading") {
        return (
          <div className="text-center mt-2">
            <span>
              Adding Photos <b>{uploads.length}</b> out of <b>{files.length}</b>{" "}
            </span>
            <ProgressBar
              className="mt-3"
              variant="success"
              now={percentageUploaded}
            />
          </div>
        );
      }
    } else {
      if (uploading == "uploaded") {
        return (
          <>
            <div className="text-center mt-2">
              <span>
                Successfully uploaded all {masterUploads.length} photos
              </span>
              <ProgressBar
                className="mt-3"
                variant="success"
                now={percentageUploaded}
              />
            </div>
          </>
        );
      }
    }
    if (uploading == "pending") {
      return null;
    }
  };

  const getCollectionDetails = async (data) => {
    try {
      let res = await dispatch(
        CollectionActions.getCollectionDetailsByExternalLink(data)
      );
      let apiData = res.data.data;
      if(apiData && Object.keys(apiData)!=0){
        return apiData.mintPerWallet;
      }
      return 0
    }
    catch(error){
      console.log(error);

    }
   
  };

  const checkSupply = async (data) => {
    let res = await dispatch(
      CollectionActions.getCollectionDetailsByExternalLink(data)
    );
    let supply = res.data.data.supply;
    if (supply == 0) {
      return false;
    }
    return true;
  };

  const submitForm = async (values) => {
   const {startLoader, stopLoader}  = LoadingActions;
    try {
      if (masterUploads.length > 0) {
        await dispatch(startLoader())
        // history.push({
        //   pathname:
        //     "/auth/collection/details/" + history.location.state.externalLink,
        // });
        let res = await checkSupply(
          history.location.state.externalLink
        );
        if (res) {
          setTimeout(async () => {
            await dispatch(stopLoader())
            history.push({
              pathname: "/auth/collection/allCollection",
              state: { externalLink: history.location.state.externalLink },
            });
            toast.success("NFT's have been added successfully");
          }, 5000);
        } else {
          throw new Error("Please connect wallet and try again");
        }
      } else {
        toast.error("Please select NFT's to upload");
      }
    } catch (error) {
      await dispatch(stopLoader())
      toast.error(error.message);
    }
  };

  const createCollectionNfts = async (data) => {
    const { createCollectionNfts, allowNFTCreation } = CollectionActions;
    try {
      let res = await dispatch(createCollectionNfts(data));
      // toast.success(res.data.message);
      return res;
    } catch (error) {
      await dispatch(allowNFTCreation(true));
      // console.log(error);
    }
  };

  const createNftsInChunks = async (chunkArray) => {
    try {
      if (chunkArray.length != 0) {
        let data = {
          nft: chunkArray,
        };
        let res = await createCollectionNfts(data);
        if (timeout) clearTimeout(timeout);
        if (res.data.status === "200") {
          setCurrentChunk((prevChunk) => prevChunk + 1);
        } else {
          toast.error("Error uploading files");
          await dispatch(LoadingActions.startLoader());
        }
      }
    } catch (error) {
      timeout = setTimeout(() => {
        createNftsInChunks(chunkArray);
      }, 5000);

      // console.log(error);
      throw error;
    }
  };

  const openAttributeModal = () => {
    if (masterUploads.length > 0) {
      setShowAttributeModal(true);
    } else {
      toast.error("Please select NFT's to upload");
    }
  };

  useEffect(async () => {
    if (masterUploads.length != 0)
      await dispatch(
        CollectionActions.saveNftUpload({
          collectionId: collectionId,
          nftUploads: nftUploads + masterUploads.length,
        })
      );
  }, [masterUploads]);


  return (
    <Layout>
      <Container fluid className="createNew uploadNFTsec">
        <Container className="Creat mb-5">
          <Form onSubmit={handleSubmit(submitForm)}>
            <div className={`${styles.imageUpload} QAns`}>
              <label for="file-input1">
                <Image src={carbon} onClick={() => handleClick("fileItems")} />
                {/* <p>
                  JPG, PNG, SVG, GIF, MP4, WEBM, MP3, WAV, OGG, GLB, GLTF. Max
                  size: 1 GB
                </p> */}
                <p>
                  JPG, PNG, SVG, GIF. Max
                  size: {Enviroments.NFT_LIMITS.FILE_SIZE_LIMIT} MB (per image)
                </p>
                {showFileUploadedSuccessMessage()}
              </label>
              {/* <input className="ms-5" id="file-input" type="file" /> */}
              <Field
                className="ms-md-2 ms-lg-5"
                onChange={selectImage}
                id="fileItems"
                type="file"
                name="items"
                accept={acceptedfileList.join(",")}
                validate={[required()]}
                component={FormField}
                multiple={true}
              />
            </div>
            <Button
              className="Parchase cmnBtn"
              type="submit"
              disabled={uploading == "uploading"}
            >
              Submit
            </Button>
            <span className="px-2 orSec">or</span>
            <Button
              className="Parchase cmnBtn"
              type="button"
              disabled={uploading == "uploading"}
              onClick={openAttributeModal}
            >
              Add Attributes
            </Button>
          </Form>
        </Container>
      </Container>
      <AttributesModal
        show={showAttributeModal}
        setShow={setShowAttributeModal}
        collectionId={collectionId}
      />
    </Layout>
  );
}

const fields = ["items"];

let uploadNftForm = reduxFormHelper({
  form: "uploadNftForm",
  fields,
  component: UploadNft,
});

export default withRouter(uploadNftForm);
