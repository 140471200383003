import React from "react";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { CollectionActions } from "../../../redux/actions/collection.action";
import walletService from "../../../services/wallet.service";
import ConnectWalletModal from "../ConnectWalletModal/ConnectWalletModal";

function ConnectWallet({ isChange, className, style }) {
  const [showConnectWallet, setShowConnectWallet] = useState(false);
  const dispatch = useDispatch();

  const handleWalletSelected = async (wallet) => {
    const { saveCollectionWalletAddress, saveWalletType } = CollectionActions;
    if (wallet) {
      const account = await walletService.connectWallet(wallet, { dispatch });
      console.log({ account });
      dispatch(
        saveCollectionWalletAddress({
          walletAddress: account,
        })
      );
      dispatch(saveWalletType({ walletType: wallet }));
    } else {
      dispatch(saveCollectionWalletAddress({ walletAddress: "" }));
      dispatch(saveWalletType({ walletType: "" }));
    }
  };

  return (
    <>
      <Button
        className={`cmnBtn2  cmnBtn ${className}`}
        type="button"
        style={style}
        onClick={() => setShowConnectWallet(true)}
      >
        {isChange ? "Change" : "Connect Wallet"}
      </Button>

      <ConnectWalletModal
        show={showConnectWallet}
        handleWalletSelected={handleWalletSelected}
        handleClose={() => setShowConnectWallet(false)}
      />
    </>
  );
}

export default ConnectWallet;
