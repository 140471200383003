import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { Layout } from "../../../common";
import "./CreateNew.scss";
import Image from "react-bootstrap/Image";
import { Field, change, reduxForm, formValueSelector } from "redux-form";
import { FormField } from "../../../common/FormField";

import {
  length,
  numericality,
  required,
  validateForm,
} from "redux-form-validators";
import "flatpickr/dist/themes/material_green.css";
import moment from "moment";

import Flatpickr from "react-flatpickr";
import { CollectionActions } from "../../../../redux/actions/collection.action";
import { connect, useDispatch } from "react-redux";
import { LoadingActions } from "../../../../redux/actions/loading.action";
import s3Service from "../../../../services/s3.service";
import { toasts as toast } from "../../../common/Toast/Toast";
import { compose } from "redux";
import FileValidator from "../../../../services/fileValidation.service";
import { useSelector } from "react-redux";
import swalService from "../../../../services/swal.service";
import web3Service from "../../../../services/web3.service";
import { PersistActions } from "../../../../redux/actions/persist.action";

const CreateNew = (props) => {
  const dispatch = useDispatch();
  const [imageName, setImageName] = useState("");
  const [logoFile, setLogoFile] = useState({});
  const [networks, setNetworks] = useState([]);
  const [date, setDate] = useState(null);

  const { handleSubmit, history } = props;
  const ownerWalletAddress = useSelector(
    (state) => state.collection?.collections?.walletAddress
  );

  const [disableAllFields, setDisableAllFields] = useState(false);

  const handleClick = (id) => {
    document.getElementById(id).click();
    const name = id.split("file")[1].toLowerCase();
    setImageName(name);
  };

  const selectImage = (file) => {
    let res = FileValidator.validate(file, acceptedfileList);
    if (res) {
      var reader = new FileReader();
      reader.onload = function (e) {
        props.change(imageName, e.target.result);
      };
      reader.readAsDataURL(file);
      setLogoFile(file);
    } else {
      toast.error("Please select image file");
    }
  };

  const uploadImageToS3 = async (file) => {
    try {
      await dispatch(LoadingActions.startLoader());
      let res = await s3Service.uploadFileToS3Bucket(file);
      await dispatch(LoadingActions.stopLoader());
      return res.Location;
    } catch (error) {
      await dispatch(LoadingActions.stopLoader());
      // console.log(error);
    }
  };

  const acceptedfileList = [
    "image/png",
    "image/jpeg",
    // ".glb",
    // ".gltf"
  ];

  const submitForm = async (values) => {
    let {
      logo,
      name,
      description,
      royality,
      blockChain,
      price,
      walletAddress,
      symbol,
      mintPerWallet,
      contractName
      // supply,
    } = values;
    price = +price;
    if (price <= 0) {
      toast.error("Price can't be 0");
      return false;
    }
    price = String(price);
    logo = await uploadImageToS3(logoFile);
    royality = Number(royality).toFixed(2);

    let data = {
      logo,
      name:name.toLowerCase(),
      description,
      royality,
      blockChain: blockChain.toUpperCase(),
      externalLink: createUniqueExternalLink(name),
      releaseDate: "",
      price,
      walletAddress,
      symbol: symbol.toUpperCase(),
      mintPerWallet,
      contractName:createUniqueName(contractName).toLowerCase()
      // supply,
    };
    console.log({data});
    createCollection(data);
  };

  const createCollection = async (data) => {
    const { startLoader, stopLoader } = LoadingActions;
    try {
      const {
        createCollection,
        getCollectionDetailsByExternalLink,
        allowNFTCreation,
      } = CollectionActions;
      await dispatch(startLoader());
      let res = await dispatch(createCollection(data));
      toast.success(res.data.message);
      setTimeout(async () => {
        let collectionData = await dispatch(
          getCollectionDetailsByExternalLink(data.externalLink)
        );
        await dispatch(allowNFTCreation(true));
        const { _id, releaseDate } = collectionData.data.data;
        history.push({
          pathname: `/auth/collection/UploadNft/${_id}`,
          state: {
            externalLink: data.externalLink,
            releaseDate: releaseDate,
          },
        });
        await dispatch(
          CollectionActions.saveNftUpload({
            collectionId: _id,
            nftUploads: 0,
          })
        );
        await dispatch(stopLoader());
      }, 5000);
    } catch (error) {
      await dispatch(stopLoader());
      // console.log(error);
    }
  };

  const createUniqueName = (value) => {
    return (
      value.split(" ").join("").trim().toLowerCase() +
      "" +
      new Date().getTime().toString()
    );
  };

  const createUniqueExternalLink = (value) => {
    var result = "";
    let length = 10;
    let characters =
      value.split(" ").join("").trim().toLowerCase() +
      "" +
      new Date().getTime().toString();
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  const formatDate = (date) => {
    let newDate = "";
    if (date instanceof Date) {
      newDate = moment(date).format("MM-DD-YYYY H:mm:ss");
      return newDate;
    }
    toast.error("Please select datetime");
    return null;
  };

  const getContracts = async () => {
    try {
      const { getContracts } = CollectionActions;
      let res = await dispatch(getContracts());
      setNetworks(
        res.data.data.map((obj) => {
          return {
            value: obj._id,
            label: obj.blockChain,
          };
        })
      );
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    getContracts();
  }, []);

  const getCurrentDateTime = () => {
    return new Date(moment().format("MM-DD-YYYY H:mm"));
  };


  useEffect(async () => {
    const {saveWalletAddress} = PersistActions
    try {
      if(!ownerWalletAddress) {
        let accounts = await web3Service.getAccounts()
        if(accounts.length == 0){
          setDisableAllFields(true)
          throw new Error("Please connect wallet")
        } else {

          props.change("walletAddress",accounts[0].toLowerCase());
          await dispatch(saveWalletAddress(accounts[0].toLowerCase()))
          setDisableAllFields(false)
        }
      
      } else {
        props.change("walletAddress",ownerWalletAddress.toLowerCase());
        await dispatch(saveWalletAddress(ownerWalletAddress.toLowerCase()))
        setDisableAllFields(false)
      }
    } 
    catch(error){
      setDisableAllFields(true)
      await swalService.confirmAction(error.message)
      web3Service.enableWallet()
    }
    
  },[ownerWalletAddress]);

  return (
    <Layout>
      <Container fluid className="createNew">
        <Container className="Creat my-5">
          <Form onSubmit={handleSubmit(submitForm)}>
            <Row className="gx-5">
              <Col className="leftSide" sm={12} md={8}>
                <Form.Group
                  className="Name QAns"
                  controlId="formPlaintexttext  "
                >
                  <Form.Label>Name</Form.Label>
                  <Field
                    name="name"
                    className="form-control "
                    type="text"
                    component={FormField}
                    placeholder="Project Name "
                    disabled={disableAllFields}
                  />
                </Form.Group>

                <Form.Group
                  className="Name QAns"
                  controlId="formPlaintexttext  "
                >
                  <Form.Label>Contract Name</Form.Label>
                  <Field
                    name="contractName"
                    className="form-control "
                    type="text"
                    component={FormField}
                    placeholder="Enter contract name or anything else"
                    disabled={disableAllFields}
                  />
                </Form.Group>

                <Form.Group
                  className="Name QAns"
                  controlId="formPlaintexttext  "
                >
                  <Form.Label>Symbol</Form.Label>
                  <Field
                    name="symbol"
                    className="form-control"
                    type="text"
                    component={FormField}
                    placeholder="Symbol"
                    disabled={disableAllFields}
                  />
                </Form.Group>

                <Form.Group
                  className="Description QAns"
                  controlId="formPlaintexttext "
                >
                  <Form.Label>Description</Form.Label>
                  <Field
                    name="description"
                    className="form-control  "
                    type="text"
                    component={FormField}
                    placeholder="Description"
                    disabled={disableAllFields}
                  />
                </Form.Group>
                <Form.Group
                  className="Price QAns"
                  controlId="formPlaintexttext"
                >
                  <Form.Label>Price</Form.Label>
                  <Field
                    name="price"
                    className="form-control"
                    type="text"
                    component={FormField}
                    placeholder="e. g 0.1, 0.01, 0.001"
                    disabled={disableAllFields}
                  />
                </Form.Group>
                <Form.Group
                  className="Max Buying Quantity QAns"
                  controlId="formPlaintexttext"
                >
                  <Form.Label>Wallet Address</Form.Label>
                  <Field
                    name="walletAddress"
                    className="form-control"
                    type="text"
                    component={FormField}
                    placeholder="1F1tAaz5x1HUXrCNLbtMDqcw6o5GNn4xqX"
                    disabled={true}
                  />
                  {/* wallletlsdkfjfdjklsdlkjflksdjf  */}
                </Form.Group>
                <Form.Group
                  className="Preset Royalty QAns"
                  controlId="formPlaintexttext"
                >
                  <Form.Label>Preset Royalty</Form.Label>
                  <Field
                    name="royality"
                    className="form-control"
                    type="text"
                    component={FormField}
                    placeholder="e. g use 5 for 5%"
                    disabled={disableAllFields}
                  />
                </Form.Group>

                <Form.Group
                  className="Launch Time QAns "
                  controlId="formPlaintexttext"
                >
                  <Form.Label>Blockchain</Form.Label>
                  <Field
                    name="blockChain"
                    className="form-control"
                    type="select"
                    component={FormField}
                    placeholder="Select Network"
                    children={networks}
                    disabled={disableAllFields}
                  />
                </Form.Group>
                <Form.Group
                  className="Preset Royalty QAns"
                  controlId="formPlaintexttext"
                >
                  <Form.Label>Max. Mint per Wallet</Form.Label>
                  <Field
                    name="mintPerWallet"
                    className="form-control"
                    type="text"
                    component={FormField}
                    placeholder="e. g 10"
                    disabled={disableAllFields}
                  />
                </Form.Group>
              </Col>
              <Col className="rightSide" sm={12} md={4}>
                <div className="plus ms-md-2 ms-lg-5">
                  <label className="form-label">Logo</label>
                  <div class="image-upload">
                    <label onClick={() => handleClick("fileLogo")}>
                      <div className="uploadSec">
                        <Image
                          className="plus"
                          fluid
                          src={
                            !props.logo
                              ? require("../../../../assets/images/Plus.svg")
                                .default
                              : props.logo
                          }
                        />
                      </div>
                    </label>
                    <Field
                      className="ms-md-2 ms-lg-5"
                      onChange={selectImage}
                      id="fileLogo"
                      type="file"
                      name="logo"
                      accept={acceptedfileList.join(",")}
                      component={FormField}
                      multiple={false}
                      disabled={disableAllFields}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Button className=" Parchase cmnBtn" type="submit">
              NEXT
            </Button>
          </Form>
        </Container>
      </Container>
    </Layout>
  );
};

const validate = validateForm({
  name: [required(), length({ max: 50 })],
  contractName: [required(), length({ max: 50 })],
  symbol: [required(), length({ max: 6 })],
  description: [required()],
  // qty: [required(), numericality({ int: true, ">=": 1 })],
  royality: [
    required(),
    numericality({
      float: true,
      ">=": 0,
      "<=": 20,
      msg: "must be between 0 to 20",
    }),
  ],
  mintPerWallet: [
    required(),
    numericality({
      integer: true,
      ">=": 1,
      "<=": 100,
      msg: "must be between 1 to 100 and integer only",
    }),
  ],
  // supply: [required(), numericality({ int: true, ">=": 1 })],
  // date: [required(), date({ ">": "today", msg: "must be in the future" })],
  logo: [required()],
  price: [
    required(),
    numericality({ float: true, ">": 0 }),
    length({
       max: 5,
       msg:"too many digits only 3 allowed" 
    }),
  ],
  blockChain: [required()],
  walletAddress: [required(), length({ min: 42, max: 42 })],
});

const createCollectionForm = reduxForm({
  enableReinitialize: true,
  validate,
  touchOnChange: true,
  form: "CreateNewForm",
});

const formSelector = formValueSelector("CreateNewForm");

const selector = (state) => ({
  logo: formSelector(state, "logo"),
  cover: formSelector(state, "cover"),
  background: formSelector(state, "background"),
});

export default compose(connect(selector), createCollectionForm)(CreateNew);
