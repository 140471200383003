import s3Service from "../../services/s3.service";
import { LoadingActions } from "./loading.action";

const uploadImageToS3 = (file) => async (dispatch, getState) => {
  try {
    await dispatch(LoadingActions.startLoader());
    let res = await s3Service.uploadFileToS3Bucket(file);
    await dispatch(LoadingActions.stopLoader());
    return res.Location;
  } catch (error) {
    await dispatch(LoadingActions.stopLoader());
    // console.log(error);
  }
};


export const FileActions = {
    uploadImageToS3
}