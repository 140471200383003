import React, { useEffect, useState } from "react";
import { useMemo } from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import useQuery from "../../../hooks/useQuery";
import { withRouter } from "react-router-dom";
import { CollectionActions } from "../../../redux/actions/collection.action";
import { toasts as toast } from "../Toast/Toast";
import ConnectWallet from "../ConnectWallet/ConnectWallet";

function Whitelist({
  collectionDetails,
  getCustomizedColor,
  walletAddress,
  connectWallet,
  match,
  invitation,
}) {
  const [isWhiteListed, setIsWhiteListed] = useState(false);

  const [isCreator, setIsCreator] = useState(null);

  const [userWhitelisted, setUserWhitelisted] = useState(false);
  const preSaleDate = collectionDetails.whiteListEndTime;
  const whitelistStartDate = collectionDetails.releaseDate;

  const dispatch = useDispatch();

  const query = useQuery();

  const inviteCode = query.get("code");

  const [isAllowedInvitationWhitelist, setIsAllowedInvitationWhitelist] =
    useState(null);

  const checkForWhiteListAndMint = async () => {
    try {
      if (!isCollectionLauched() && collectionDetails.status === "APPROVED") {
        if (isAllowedInvitationWhitelist) {
          await whitelistUser();
        } else {
          throw new Error("Link expired");
        }
      }
      if (isCollectionLauched() && collectionDetails.status === "APPROVED") {
        let res = await checkWhitelistUser();
        setUserWhitelisted(res);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const whitelistUser = async () => {
    try {
      const { whitelistUser } = CollectionActions;
      let res = await checkWhitelistUser();
      if (!res) {
        let data = {
          collection: collectionDetails._id,
          walletAddress,
          invitation: invitation.inviteId,
        };
        await dispatch(whitelistUser(data));

        setUserWhitelisted(true);
      } else {
        setUserWhitelisted(true);
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const checkWhitelistUser = async (address) => {
    try {
      if (!collectionDetails._id) return false;
      let data = {
        collection: collectionDetails._id,
        walletAddress: address || walletAddress,
      };
      const { checkIsWhiteListed } = CollectionActions;
      let res = await dispatch(checkIsWhiteListed(data));
      return res.data.data.isWhiteListed;
    } catch (error) {
      // console.log(error);
    }
  };

  const isCollectionLauched = () => {
    if (!!preSaleDate) {
      if (new Date().getTime() < new Date(preSaleDate).getTime()) return false;
    }
    return true;
    // if (new Date().getTime() < 164862916150) return true;
  };

  const isWhitelistOpened = () => {
    if (collectionDetails._id) {
      if (collectionDetails.isWhiteListedEnable) {
        if (new Date().getTime() > new Date(whitelistStartDate).getTime()) {
          if (invitation.type == "PUBLIC") {
            if (
              new Date().getTime() <
              new Date(collectionDetails.whiteListEndTime).getTime()
            ) {
              return true;
            } else return false;
          } else if (invitation.type == "PRIVATE") {
            if (
              new Date().getTime() <
              new Date(collectionDetails.preSaleStartTime).getTime()
            ) {
              return true;
            } else return false;
          }
        }
      }
      // if (
      //   new Date().getTime() > new Date(whitelistStartDate).getTime() &&
      //   new Date().getTime() < new Date(preSaleDate).getTime()
      // )
      //   return true;

      return false;
    }
  };

  const debugVariables = () => {
    console.table({
      isCreator,
      walletAddress,
      isCollectionLauched: isCollectionLauched(),
      isWhitelistOpened: isWhitelistOpened(),
    });

    console.table(showConnectWalletAndAddToWhitelist);
  };

  useEffect(async () => {
    setIsCreator(
      !!walletAddress &&
        walletAddress === collectionDetails.walletAddress.toLowerCase()
    );
    if (!!walletAddress) {
      let res = await checkWhitelistUser(walletAddress);
      setUserWhitelisted(res);
    }
  }, [walletAddress, collectionDetails]);

  const showConnectWalletAndAddToWhitelist = useMemo(() => {
    let obj = { isConnectWallet: false, isWhitelist: false };

    if (collectionDetails.status === "APPROVED") {
      if (inviteCode) {
        // if it's not a creator
        if (isCreator != null && isCreator == false) {
          // check if wallet is not connected

          if (isWhitelistOpened()) {
            if (!walletAddress) {
              // show connect button
              obj.isConnectWallet = true;
            } else {
              if (!userWhitelisted) {
                // hide connect wallet and show add to whitelist button
                obj.isWhitelist = true;
              } else {
                obj.isWhitelist = false;
              }
              obj.isConnectWallet = false;
            }
          } else {
            if (!walletAddress) {
              obj.isConnectWallet = true;
            }
          }
        } else {
          // if it is a creator
          obj.isWhitelist = false;
          obj.isConnectWallet = false;
        }
      } else {
        if(isCreator==false && !walletAddress){
          obj.isConnectWallet = true;
          obj.isWhitelist = false;
        } else {
          obj.isWhitelist = false;
          obj.isConnectWallet = false;
        }
       
      }
    }
    return obj;
  }, [
    collectionDetails,
    walletAddress,
    userWhitelisted,
    isCollectionLauched(),
    isWhitelistOpened(),
    isCreator,
  ]);

  const checkInvitationWhiteList = () => {
    setIsAllowedInvitationWhitelist(
      invitation.numberOfWalletJoined < invitation.maxNumberOfWallet
    );
  };

  useEffect(() => {
    checkInvitationWhiteList();
  }, [invitation]);

  const isUserCollectionCreator = useMemo(() => {
    if (match.path.indexOf("auth") != -1) {
      return true;
    }
    return false;
  }, [match]);

  return (
    <>
      <>
        <div className="row d-flex">
          {/* {JSON.stringify(isCreator)} */}
          {debugVariables()}
          <>
            {showConnectWalletAndAddToWhitelist.isConnectWallet == true && (
              <div className="col text-center">
                {/* <Button
                  className=" Parchase cmnBtn"
                  type="button"
                  onClick={connectWallet}
                  style={getCustomizedColor("button")}
                >
                  CONNECT WALLET
                </Button> */}
                <ConnectWallet style={getCustomizedColor("button")}/>
                {isWhitelistOpened() && (
                  <div className="mt-3">
                    "connect wallet" will put you on the whitelist
                  </div>
                )}
              </div>
            )}
            {showConnectWalletAndAddToWhitelist.isWhitelist == true && (
              <div className="col">
                <Button
                  className="mb-5 Parchase cmnBtn"
                  type="button"
                  style={getCustomizedColor("button")}
                  onClick={checkForWhiteListAndMint}
                >
                  Add my wallet to whitelist
                </Button>
              </div>
            )}
          </>
        </div>
      </>

      {/* show walletaddress only if wallet is connected */}

      {/* {!!walletAddress && (
        <h5
          style={getCustomizedColor("heading")}
          className="d-flex align-items-center mb-4"
        >
          Wallet:{" "}
          <p style={getCustomizedColor("text")} className="mb-0 ms-2">
            {" "}
            {walletAddress}{" "}
          </p>
        </h5>
      )} */}

      {/* <div>
        {userWhitelisted && isWhitelistOpened() && (
          <p>You have been added to whitelist</p>
        )}
      </div> */}
    </>
  );
}

export default withRouter(Whitelist);
